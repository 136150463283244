import logo from './logolla.png';
import logo2 from './lla.jpeg';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
       
        <a
          className="App-link"
          href="https://app.lacteoslosandesca.com/elecciones/views/login"
          target="_self"
          rel="noopener noreferrer"
        >
          
          <img src={logo2} className="App-logo2" alt="logo2" />
          <th>Elecciones 2024</th>


        </a>

      </header>
    </div>
  );
}

export default App;
